<template>
  <div class="UnitDetail">
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="1">
        <Detail></Detail>
      </el-tab-pane>

      <el-tab-pane label="参赛信息" name="2">
        <Competition v-if="tab.name == '2'"></Competition>
      </el-tab-pane>

      <el-tab-pane label="选手信息" name="3">
        <Player v-if="tab.name == '3'"></Player>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Detail from '../../components/unitbaseinfo.vue'
import Competition from '../../components/unitcompetition.vue'
import Player from '../../components/unitplayer.vue'
export default {
  data() {
    return {
      nav: this.$route.params.back,
      unitId: this.$route.params.unitId,
      competitionId:this.$route.params.competitionId,
      activeName: '1',
      tab: {},
      name: '',
    }
  },
  components: {
    Detail,
    Competition,
    Player,
  },
  mounted() {
    this.updateType()
  },
  methods: {
    updateType() {
      let type = this.$route.params.name
      //通过拿到的值不同，更改activeName的值
      if (type == '1') {
        this.tab.name = '1'
        this.activeName = '1'
      } else if (type == '2') {
        this.tab.name = '2'
        this.activeName = '2'
      } else if (type == '3') {
        this.tab.name = '3'
        this.activeName = '3'
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    handleClick(tab, event) {
      console.log(tab, event)
      this.tab = tab
      if (tab.name == '1') {
        this.name = 1
      } else if (tab.name == '2') {
        this.name = 2
      } else if (tab.name == '3') {
        this.name = 3
      }
      this.$router.push({
        name: 'UnitDetail',
        params: { back: this.nav, unitId: this.unitId, name: this.name,competitionId:this.competitionId },
      })
    },
  },
}
</script>

<style lang="less">
.UnitDetail {
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .el-dialog__header {
    text-align: center;
  }
}
</style>