<template>
  <div class="UnitPlayer">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >姓名：</span
          >
          <el-input
            style="margin-right: 20px; width: 180px"
            v-model="playerName"
          ></el-input>

          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >证件号码：</span
          >
          <el-input
            style="margin-right: 20px; width: 180px"
            v-model="cardNo"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleSearch"
            >搜索</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="550">
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="生日">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="身份证号">
            <template slot-scope="scope">
              <span>{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column label="手机号">
            <template slot-scope="scope">
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="350">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      teamId: this.$route.params.unitId,
      playerName: "",
      cardNo: "",
      tableData: [],
      name: "",
      card: "",
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    async handleSearch() {
      try {
        const result = await this.$api.findApplyUnitPlayer(
          `search_EQ_teamId=${this.teamId}&search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_playerName=${this.playerName}&search_EQ_cardNo=${this.cardNo}`
        );
        this.tableData = result.pageResults;
        this.total = result.totalCount;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    handleDetail(index, row) {
      console.log(index, row);
      this.$router.push({
        name: "UnitDetail",
        params: { competitionId: this.id, unitId: row.id, back: row.username },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    async getTableData() {
      try {
        const result = await this.$api.findApplyUnitPlayer(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.total = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.UnitPlayer {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
