<template>
  <div class="bar">
    <el-card class="box-card">
      <div class="text item">
        单位名称
        <span class="info">{{ tableData.username }}</span>
      </div>
      <div class="text item">
        单位负责人
        <span class="info">{{ tableData.contactName }}</span>
      </div>
      <div class="text item">
        单位负责人电话
        <span class="info">{{ tableData.contactPhone }}</span>
      </div>
      <div class="text item">
        单位邮箱
        <span class="info">{{ tableData.email }}</span>
      </div>
      <div class="text item">
        国籍
        <span class="info">{{ tableData.startTime }}</span>
      </div>
      <div class="text item">
        单位电话
        <span class="info">{{ tableData.applyEndTime }}</span>
      </div>
      <div class="text item">
        所在省份
        <span class="info">{{ tableData.registerStartTime }}</span>
      </div>
      <div class="text item">
        所在城市
        <span class="info">{{ tableData.registerAddress }}</span>
      </div>
      <div class="text item">
        单位详细地址
        <span class="info">{{ tableData.address }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'detail',
  data() {
    return {
      id:this.$route.params.id,
      unitId:this.$route.params.unitId,
      tableData: {},
      currentPage:1,
      pageSize:10,
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    async getTableData() {
      try {
        const result = await this.$api.findApplyUnit(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        )
        this.tableData = result.pageResults.filter(item => {
          return item.id == this.unitId
        })[0]
        console.log(this.tableData)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bar {
  position: relative;
  .info {
    position: absolute;
    left: 200px;
  }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
}
</style>
