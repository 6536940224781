<template>
  <div>参赛信息</div>
</template>

<script>
export default {
data() {
  return {
    
  }
}
}
</script>

<style>

</style>